import axios from "axios"
import api from 'dashboards/global/middleware.js'

const makeRequest = axios

export const getPaymentMethods = async () =>
  makeRequest({
    method: "get",
    url: `/api/stripe/payments`,
  })

export const createCustomerInfo = async () =>
  makeRequest({
    method: "post",
    url: `/api/stripe/intents`,
})

export const confirmOrder = async (data) =>
  makeRequest({
    method: "post",
    url: `/api/order_created`,
    data: data,
  })

export const confirmOrderPayment = async (order_no, data) =>
  makeRequest({
    method: "post",
    url: `/api/orders/${order_no}/confirm_payment`,
    data: data,
  })

export const checkInventory = async (params) => {
  return api.get('/api/inventory/stock', { params })
}

export const cancelOrder = async (order_no) => {
  return api.put(`/dashboard/api/orders/${order_no}/cancel`)
}
