import { Button,  Form, Input, Row, Typography, Col } from 'antd';
import { isEmpty } from 'lodash'
import React from 'react';
import { t } from '../../helpers/translation';
import styles from './styles.js';

const SearchForm = ({ onFinish, form, onSearch, value, setSearchType, current_user }) => {
  return (
    <Form
      form={form}
      name="search"
      onFinish={onFinish}
      initialValues={{}}
      scrollToFirstError
    >
      <Row style={styles.row}>
        <Form.Item
          name="search"
          style={styles.form}
        >
          <Input
            value={value}
            onChange={onSearch}
            style={styles.searchBar}
            placeholder={t('product_search.enter_keywords')}
            onFocus={() => setSearchType('default')}
            onBlur={() => {
              if(isEmpty(value)){
                setSearchType('default')
              }
            }}
          >
          </Input>
          <Button style={styles.searchButton}>
            <img src="../../images/menu_search.svg" />
          </Button>
        </Form.Item>
      </Row>
    </Form>
  );
};

export default SearchForm
